import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';
import * as fromDashboard from 'reducers/dashboard';
import { getSuggestedPairings } from 'actions/suggestedPairings';
import { getBehavioralDiscounts } from 'actions/behavioralDiscounts';
import { mapBasketAndMealsToReceiptParams } from 'helpers/requestTransformers';
import { getMenu } from 'actions/menus';
import { undoBasketEdits } from 'actions/basketEdits';

export const GET_BASKET = 'GET_BASKET';
export const GET_BASKET_REQUEST = 'GET_BASKET_REQUEST';
export const GET_BASKET_RESPONSE = 'GET_BASKET_RESPONSE';
export const GET_BASKET_ERROR = 'GET_BASKET_ERROR';

export const GET_BASKET_SUMMARIES = 'GET_BASKET_SUMMARIES';
export const GET_BASKET_SUMMARIES_REQUEST = 'GET_BASKET_SUMMARIES_REQUEST';
export const GET_BASKET_SUMMARIES_RESPONSE = 'GET_BASKET_SUMMARIES_RESPONSE';
export const GET_BASKET_SUMMARIES_ERROR = 'GET_BASKET_SUMMARIES_ERROR';

export const SKIP_BASKET = 'SKIP_BASKET';
export const SKIP_BASKET_REQUEST = 'SKIP_BASKET_REQUEST';
export const SKIP_BASKET_RESPONSE = 'SKIP_BASKET_RESPONSE';
export const SKIP_BASKET_ERROR = 'SKIP_BASKET_ERROR';

export const UNSKIP_BASKET = 'UNSKIP_BASKET';
export const UNSKIP_BASKET_REQUEST = 'UNSKIP_BASKET_REQUEST';
export const UNSKIP_BASKET_RESPONSE = 'UNSKIP_BASKET_RESPONSE';
export const UNSKIP_BASKET_ERROR = 'UNSKIP_BASKET_ERROR';

export const ADD_BASKET = 'ADD_BASKET';
export const ADD_BASKET_REQUEST = 'ADD_BASKET_REQUEST';
export const ADD_BASKET_RESPONSE = 'ADD_BASKET_RESPONSE';
export const ADD_BASKET_ERROR = 'ADD_BASKET_ERROR';

export const UPDATE_BASKET = 'UPDATE_BASKET';
export const UPDATE_BASKET_REQUEST = 'UPDATE_BASKET_REQUEST';
export const UPDATE_BASKET_RESPONSE = 'UPDATE_BASKET_RESPONSE';
export const UPDATE_BASKET_ERROR = 'UPDATE_BASKET_ERROR';
export const UPDATE_BASKET_ADDRESS = 'UPDATE_BASKET_ADDRESS';
export const UPDATE_BASKET_ADDRESS_RESPONSE = 'UPDATE_BASKET_ADDRESS_RESPONSE';
export const CLEAR_BASKET_ADDRESS = 'CLEAR_BASKET_ADDRESS';

export const DONATE_BASKET = 'DONATE_BASKET';
export const DONATE_BASKET_REQUEST = 'DONATE_BASKET_REQUEST';
export const DONATE_BASKET_RESPONSE = 'DONATE_BASKET_RESPONSE';
export const DONATE_BASKET_ERROR = 'DONATE_BASKET_ERROR';

export const GET_RECEIPT = 'GET_RECEIPT';
export const GET_RECEIPT_REQUEST = 'GET_RECEIPT_REQUEST';
export const GET_RECEIPT_RESPONSE = 'GET_RECEIPT_RESPONSE';
export const GET_RECEIPT_ERROR = 'GET_RECEIPT_ERROR';

export const GET_UPCOMING_RECEIPT = 'GET_UPCOMING_RECEIPT';
export const GET_UPCOMING_RECEIPT_REQUEST = 'GET_UPCOMING_RECEIPT_REQUEST';
export const GET_UPCOMING_RECEIPT_RESPONSE = 'GET_UPCOMING_RECEIPT_RESPONSE';
export const GET_UPCOMING_RECEIPT_ERROR = 'GET_UPCOMING_RECEIPT_ERROR';

export const GET_TRACKING = 'GET_TRACKING';
export const GET_TRACKING_REQUEST = 'GET_TRACKING_REQUEST';
export const GET_TRACKING_RESPONSE = 'GET_TRACKING_RESPONSE';
export const GET_TRACKING_ERROR = 'GET_TRACKING_ERROR';

export const UNDONATE_BASKET = 'UNDONATE_BASKET';
export const UNDONATE_BASKET_REQUEST = 'UNDONATE_BASKET_REQUEST';
export const UNDONATE_BASKET_RESPONSE = 'UNDONATE_BASKET_RESPONSE';
export const UNDONATE_BASKET_ERROR = 'UNDONATE_BASKET_ERROR';

export const GET_RETAIL_STORE = 'GET_RETAIL_STORE';
export const GET_RETAIL_STORE_REQUEST = 'GET_RETAIL_STORE_REQUEST';
export const GET_RETAIL_STORE_RESPONSE = 'GET_RETAIL_STORE_RESPONSE';
export const GET_RETAIL_STORE_ERROR = 'GET_RETAIL_STORE_ERROR';

export const GET_PROJECTED_COSTS = 'GET_PROJECTED_COSTS';
export const GET_PROJECTED_COSTS_REQUEST = 'GET_PROJECTED_COSTS_REQUEST';
export const GET_PROJECTED_COSTS_RESPONSE = 'GET_PROJECTED_COSTS_RESPONSE';
export const GET_PROJECTED_COSTS_ERROR = 'GET_PROJECTED_COSTS_ERROR';

export const GET_TIERED_PRICING_DETAILS = 'GET_TIERED_PRICING_DETAILS';
export const GET_TIERED_PRICING_DETAILS_REQUEST = 'GET_TIERED_PRICING_DETAILS_REQUEST';
export const GET_TIERED_PRICING_DETAILS_RESPONSE = 'GET_TIERED_PRICING_DETAILS_RESPONSE';
export const GET_TIERED_PRICING_DETAILS_ERROR = 'GET_TIERED_PRICING_DETAILS_ERROR';

export const skipBasket = (menuSlug, cta) => {
  return async dispatch => {
    const response = await dispatch(
      makeApiRequest({
        requestType: SKIP_BASKET,
        method: RequestService.skipBasket,
        params: { menuSlug },
        metadata: { cta },
      })
    );
    if (response.ok) {
      dispatch(refreshBasketsAfter(menuSlug, true));
    }
    return response;
  };
};

export const unskipBasket = (menuSlug, cta, isUnskipViaSlideout = false, mealId = null) => {
  return async dispatch => {
    const params = { menuSlug, mealId, cta, isUnskipViaSlideout };
    const response = await dispatch(
      makeApiRequest({
        requestType: UNSKIP_BASKET,
        method: RequestService.unskipBasket,
        params,
        metadata: { cta },
      })
    );
    if (response.ok) {
      if (isUnskipViaSlideout) {
        await dispatch(getBasket(menuSlug));
      }
      dispatch(refreshBasketsAfter(menuSlug, true));
    } else {
      if (isUnskipViaSlideout) {
        await dispatch(getMenu(menuSlug));
      }
    }
    return response;
  };
};

export const getBasket = menuSlug =>
  makeApiRequest({
    requestType: GET_BASKET,
    method: RequestService.getBasket,
    params: { menuSlug },
  });

export const donateBasket = (menuSlug, servings, cta) =>
  makeApiRequest({
    requestType: DONATE_BASKET,
    method: RequestService.donateBasket,
    params: { menuSlug, servings },
    metadata: { cta },
  });

export const getBasketSummaries = (offset = -1, limit = 6) =>
  makeApiRequest({
    requestType: GET_BASKET_SUMMARIES,
    method: RequestService.getBasketSummaries,
    params: { limit, offset },
  });

export const updateBasket = (menuSlug, params) =>
  makeApiRequest({
    requestType: UPDATE_BASKET,
    method: RequestService.updateBasket,
    params: { menuSlug, bypassReviewPrompt: true, ...params },
  });

export const undonateBasket = (menuSlug, cta) =>
  makeApiRequest({
    requestType: UNDONATE_BASKET,
    method: RequestService.undonateBasket,
    params: { menuSlug },
    metadata: { cta },
  });

export const addBasket = (menuSlug, params) =>
  makeApiRequest({
    requestType: ADD_BASKET,
    method: RequestService.addBasket,
    params: { menuSlug, ...params },
  });

export const updateBasketAddress = ({ menuSlug, addressId, isDefault }) => {
  return async dispatch => {
    const response = await dispatch(
      makeApiRequest({
        requestType: UPDATE_BASKET_ADDRESS,
        method: RequestService.updateBasketAddress,
        params: { menuSlug, address: { id: addressId } },
        metadata: {
          default: isDefault,
        },
      })
    );

    if (response.ok) {
      dispatch(getMenu(menuSlug));
      const suggestedPairingsResponse = await dispatch(getSuggestedPairings(menuSlug));
      const selectedPairings =
        (suggestedPairingsResponse.suggestedPairings &&
          suggestedPairingsResponse.suggestedPairings.filter(
            pairing => pairing.suggestedPairing.isSelected === true
          )) ||
        [];

      dispatch(
        undoBasketEdits({ basket: response.weeklyBasket, selectedPairings: selectedPairings })
      );
    }
    return response;
  };
};

export const refreshBaskets = () => async (dispatch, getState) => {
  const menuSlugs = fromDashboard.selectMenuSlugs(getState());
  return await Promise.all(menuSlugs.map(menuSlug => dispatch(getBasket(menuSlug))));
};

export const refreshBasketsAfter =
  (menuSlug, filter = false) =>
  async (dispatch, getState) => {
    const state = getState();
    const menuSlugs = fromDashboard.selectMenuSlugs(state);
    const menusToFetch = menuSlugs.slice(menuSlugs.indexOf(menuSlug) + 1);
    const filteredMenusToFetch = filter
      ? menusToFetch.filter(slug => {
          const basket = fromDashboard.selectBasket(state, slug);
          // deliveryDay is non-existent on unloaded baskets
          return basket.deliveryDay;
        })
      : menusToFetch;

    const hasBehavioralDiscounts =
      fromDashboard.selectBehavioralDiscountsForMenu(state, menuSlug).length > 0;
    hasBehavioralDiscounts && dispatch(getBehavioralDiscounts(menuSlug));

    return await Promise.all(filteredMenusToFetch.map(menuSlug => dispatch(getBasket(menuSlug))));
  };

export const getReceipt = params =>
  makeApiRequest({
    requestType: GET_RECEIPT,
    method: RequestService.getReceipt,
    params,
  });

export const getUpcomingReceipt = params =>
  makeApiRequest({
    requestType: GET_UPCOMING_RECEIPT,
    method: RequestService.getReceipt,
    params,
  });

export const getTracking = menuSlug =>
  makeApiRequest({
    requestType: GET_TRACKING,
    method: RequestService.getTracking,
    params: { menuSlug },
  });

export const getUpcomingOrderReceipt = (menuSlug, includeDiscounts = false) => {
  return async (dispatch, getState) => {
    const state = getState();
    const basket = fromDashboard.selectBasket(state, menuSlug);
    const meals = fromDashboard.selectMealsForBasket(state, basket.menu.slug);
    const selectedSuggestedPairings = fromDashboard.selectSelectedSuggestedPairingsForMenu(
      state,
      basket.menu.slug
    );

    const receiptParams = mapBasketAndMealsToReceiptParams(
      basket,
      meals,
      selectedSuggestedPairings,
      includeDiscounts
    );
    return await dispatch(getUpcomingReceipt({ ...receiptParams, menuSlug }));
  };
};

export const getProjectedCosts = weeklyBasket => {
  return async dispatch => {
    const response = await dispatch(
      makeApiRequest({
        requestType: GET_PROJECTED_COSTS,
        method: RequestService.getProjectedCosts,
        params: { weeklyBasket },
      })
    );

    return response;
  };
};

export const getTieredPricingDetails = mealPricePlanId =>
  makeApiRequest({
    requestType: GET_TIERED_PRICING_DETAILS,
    method: RequestService.getTieredPricingDetails,
    params: { id: mealPricePlanId },
  });
